<template>
<div id="bg">
  <div class="search">
    <div class="search_box">
      分类:<el-select class="width_200" @change="getList()" v-model="search.type">
        <el-option  :value=-1 label="全部"></el-option>
        <el-option  :value=0 label="漫画"></el-option>
        <el-option  :value=1 label="美图"></el-option>
        <el-option  :value=2 label="小说"></el-option>
        <el-option  :value=3 label="社区"></el-option>
      </el-select>
      <el-button type="primary" size="medium" @click="showDialog()">添加</el-button>
      <el-button type="primary" size="medium" @click="getList()">查询</el-button>
    </div>
  </div>
  <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
<!--    <el-table-column type="selection" align="center" :show-overflow-tooltip="true"></el-table-column>-->
    <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
    <el-table-column prop="type" label="分类" align="center" :show-overflow-tooltip="true">
      <template #default="scope">
        <span v-if="scope.row.type == 0">漫画</span>
        <span v-if="scope.row.type == 1">美图</span>
        <span v-if="scope.row.type == 2">小说</span>
        <span v-if="scope.row.type == 3">社区</span>
      </template>
    </el-table-column>
    <el-table-column prop="title" label="标题" align="center" :show-overflow-tooltip="true"></el-table-column>
    <el-table-column prop="sub_title" label="子标题" align="center" :show-overflow-tooltip="true"></el-table-column>
    <el-table-column prop="image" label="图片" align="center" :show-overflow-tooltip="true">
      <template #default="scope">
        <ximg :src="scope.row.image" :width="30" :height="30" alt=""></ximg>
      </template>
    </el-table-column>
    <el-table-column prop="enable" label="启用" align="center" :show-overflow-tooltip="true">
      <template #default="scope">
        <el-switch :active-value=1 :inactive-value=0 @click="enableSwitch(scope.row)" :value="scope.row.enable" ></el-switch>
      </template>
    </el-table-column>
    <el-table-column prop="is_show" label="前台可见" align="center" :show-overflow-tooltip="true">
      <template #default="scope">
        <el-switch :active-value=1 @click="showSwitch(scope.row)" :inactive-value=0 :value="scope.row.is_show" ></el-switch>
      </template>
    </el-table-column>
    <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
    <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
    <el-table-column label="操作" align="center">
      <template #default="scope">
        <span class="operation" @click="showDialog(scope.row)">编辑</span>
        <span class="operation" @click="del(scope.row)" >删除</span>
      </template>
    </el-table-column>  </el-table>
  <page :pager="pager" @query="getList()" />

  <el-dialog :title="dialogTitle" v-model="dialogVisible" width="600" height="500px" top="5vh" :close-on-click-modal="false" >
    <el-row>
      <el-col :span="4" align="right">标题</el-col>
      <el-col :span="20">
        <el-input placeholder="请输入标题" v-model="dialogAdd.title"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" align="right">子标题</el-col>
      <el-col :span="20">
        <el-input placeholder="请输入子标题" v-model="dialogAdd.sub_title"></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" align="right">封面图</el-col>
      <el-col :span="20">
        <el-upload
            class="avatar-uploader"
            action=""
            :show-file-list="false"
            :http-request="uploadImg"
            accept="image/jpeg,image/png"
            :before-upload="beforeAvatarUpload">
          <ximg v-if="imageUrl" :src="imageUrl" class="avatar"></ximg>
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" align="right">分类</el-col>
      <el-col :span="20">
        <el-select class="width_200" v-model="dialogAdd.type">
          <el-option  :value=0 label="漫画"></el-option>
          <el-option  :value=1 label="美图"></el-option>
          <el-option  :value=2 label="小说"></el-option>
          <el-option  :value=3 label="社区"></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4" align="right">权重</el-col>
      <el-col :span="20">
        <el-input type="number" v-model="dialogAdd.weight"></el-input>
      </el-col>
    </el-row>
    <el-row>
        <el-col :span="4" align="right">开启</el-col>
        <el-col :span="20">
          <el-switch :active-value=1 :inactive-value=0 v-model="dialogAdd.enable" ></el-switch>
        </el-col>
    </el-row>

    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="20">
        <el-button @click="dialogVisible=false">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="4"></el-col>
      <el-col :span="20">
      </el-col>
    </el-row>
  </el-dialog>
</div>
</template>

<script>
import page from "@/components/page";
import httpClient from "@/config/httpClient";
export default {
  name: "community_tag",
  components: {
    page,
  },
  data(){
    return {
      search:{
        type:-1,
      },
      pager: { total: 0, page: 1, rows: 50 },
      tableData:[
      ],
      dialogVisible:false,
      dialogTitle:"添加标签",
      dialogAdd:{
        title:"",
        sub_title:"",
        type:null,
        image:"",
        weight:0,
        enable:0
      },
      current_row:null,// 编辑的当前行
      imageUrl:"",
      image_width:750,
      image_height:398,
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    uploadImg(param){
      // 750*360
      var canvas = document.createElement("canvas")
      // 读取图片的宽高
      var img = document.createElement("img")
      img.src =  URL.createObjectURL(param.file)
      img.width = this.image_width
      img.height = this.image_height
      var self = this
      img.onload = function (){
        canvas.width = img.width
        canvas.height = img.height
        canvas.getContext("2d").drawImage(img,0,0,img.width,img.height)
        self.imageUrl = canvas.toDataURL(param.file.type);
        // 开始上传图片
        self.uploadTagImg(self.base64ToFile(self.imageUrl,param.file.name)) //  开始上传图片
      }
    },
    uploadTagImg(file){
      // 开始上传文件到服务器
      var _headers = {
        "is_compress":"false",
        'Name':"community",
        "is-encode":"true",

      }
      this.$common.showLoading("正在上传封面图!")
      httpClient().setHeader(_headers).upload(file).then((res)=>{
        this.$common.hideLoading()
        if (res.code==0){
          this.dialogAdd.image = res.data.filename
          this.$message({message:"图片上传成功!",type:"success"})
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    beforeAvatarUpload(file){
      // 显示图片
      if (file.type != "image/jpeg" && file.type != "image/jpg" && file.type != "image/png" ){
        this.$message({message:"文件类型["+file.type+"]不正确!",type:"error"})
        return
      }
      // 大小检测？
    },
    del(row){
      this.$confirm("删除标签: " + row.title + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        httpClient("delTag").post({"id":row.id}).then((res)=>{
          if(res.code == 0){
            this.getList()
          }else{
            this.$message({message:res.msg,type:"error"})
          }
        })
      })
    },
    enableSwitch(row){
      httpClient("updateEnable").post({"id":this.dialogAdd.id, "enable":row.enable == 0?1:0,}).then((res)=>{
        if (res.code == 0){
          row.enable = row.enable == 0?1:0
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$message({message:err,type:"error"})
      })
    },
    showSwitch(row){
      httpClient("updateIsShow").post({"id":row.id, "is_show":row.is_show == 0?1:0,}).then((res)=>{
        if (res.code == 0){
          row.is_show = row.is_show == 0?1:0
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$message({message:err,type:"error"})
      })
    },
    getList(){
      httpClient("communityTag").post(
        {"page":this.pager.page,
        "row":this.pager.rows,
        "type":this.search.type}).then((res)=>{
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({ message: res.msg, type: "error" });
        }
      },(err)=>{
        console.log(err)
      })
    },
    showDialog(row){
      this.dialogVisible = true
      this.dialogAdd = {
        id:0,
        title:"",
        sub_title:"",
        type:null,
        image:"",
        weight:0,
        enable:0
      }
      this.imageUrl = ""
      if (row){
        this.current_row = row
        this.dialogTitle = "编辑标签,ID:"+row.id
        this.imageUrl = row.image
        this.dialogAdd = {
          id:row.id,
          title:row.title,
          sub_title:row.sub_title,
          type:row.type,
          image:row.image,
          weight:row.weight,
          enable:row.enable
        }
      }else{
        this.dialogTitle = "添加标签"
      }
    },
    save(){
      // 保存
      if(this.dialogAdd.title == ""){
        this.$message({ message: "请输入标签的标题", type: "error" });
        return;
      }
      if(this.dialogAdd.sub_title == ""){
        this.$message({ message: "请输入标签的子标题", type: "error" });
        return;
      }
      if(this.dialogAdd.type == null){
        this.$message({ message: "请选择标签类型", type: "error" });
        return;
      }
      if (this.dialogAdd.id == 0){
        // if (this.dialogAdd.image == ""){
        //   this.$message({ message: "请上传封面图", type: "error" });
        //   return;
        // }
        // 添加
        httpClient("addTag").post(
          {"title":this.dialogAdd.title,
          "sub_title":this.dialogAdd.sub_title,
          "type":this.dialogAdd.type,
          "image":this.dialogAdd.image,
          "enable":this.dialogAdd.enable,
          "weight":this.dialogAdd.weight}).then((res)=>{
          if (res.code == 0){
            this.dialogVisible = false
            this.$message({ message: "标签添加成功", type: "success" });
            this.getList()
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        },(err)=>{
          console.log(err)
        })
      }else{
        // 修改
        httpClient("updateTag").post(
            {"title":this.dialogAdd.title,
              "id":this.dialogAdd.id,
              "sub_title":this.dialogAdd.sub_title,
              "type":this.dialogAdd.type,
              "image":this.dialogAdd.image,
              "enable":this.dialogAdd.enable,
              "weight":this.dialogAdd.weight}).then((res)=>{
          if (res.code == 0){
            this.dialogVisible = false
            this.$message({ message: "标签添加成功", type: "success" });
            for(var key in this.dialogAdd){
              this.current_row[key] = this.dialogAdd[key]
            }
            // this.getList()
          }else{
            this.$message({ message: res.msg, type: "error" });
          }
        },(err)=>{
          console.log(err)
        })
      }
    }
  }
}
</script>

<style>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>